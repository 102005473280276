<template>
  <div class="loading-overlay bg-light">
    <div class="my-4">
      <BSpinner class="mr-3" />
    </div>
    {{
      $gettext("Please wait while we logging you into the employer account.")
    }}
  </div>
</template>
<script>
import { BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { updateUserIntercom } from "@/utils/intercom";

export default {
  components: { BSpinner },
  props: {
    selector: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("me", ["user"])
  },
  mounted() {
    this.signInAsUser({ selector: this.selector, token: this.token })
      .then(() => {
        updateUserIntercom(this.user);
        if (this.$route.query.redirect?.length) {
          window.location = this.$route.query.redirect;
        } else {
          window.location = "/dashboard";
        }
      })
      .catch(() => {
        this.$router.push({ name: "login" });
      });
  },
  methods: {
    ...mapActions("me", ["signInAsUser"])
  }
};
</script>
